//
// _facility-landing.scss
//

$mobile: 450px;

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.facility-landing-body {
  background-color: white;
  height: 100vh;
  margin: 0;
  padding: 0;

  .facility-landing-content {
    z-index: 0;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    overflow-y: auto;
    padding-top: 32px;

    * {
      position: relative;
      z-index: 1;
    }

    .not-found-image {
      height: 276px;
      width: 276px;
      margin-bottom: 26px;
    }

    .not-found-title {
      font-size: 35px;
      font-weight: 800;
    }

    .not-found-sub-title {
      font-size: 18px;
      font-weight: 500;
      color: #6c7393;
    }

    .header {
      justify-self: center;
      background-color: white;
      width: 90%;
      height: 87.57px;
      border-radius: 30px;
      box-shadow: 0px 0px 38px 0px #6b6e720f;
      padding: 16px;

      @media (max-width: $mobile) {
        justify-content: center !important;
        width: 100%;
        height: 57px;

        .header-btn {
          display: none !important;
        }
      }
    }

    .logo {
      height: 25px;
      width: 159px;
      margin: 16px;
    }

    .first-bg-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 617px;
      height: 520px;
      z-index: 0;
      @media (max-width: $mobile) {
        display: none;
      }
    }

    .not-found-first-bg-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 617px;
      height: 520px;
      z-index: 0;
      @media (max-width: $mobile) {
        top: -90px;
      }
    }

    .title {
      padding-top: 57px;
      h1 {
        font-size: 78px;
        margin-bottom: 12px;
      }
      .buttons {
        padding-top: 32px;
      }
      padding-bottom: 85px;

      @media (max-width: $mobile) {
        width: 90%;
        display: flex;
        justify-self: center;

        .buttons {
          flex-direction: column;
          width: 100%;
        }

        h1 {
          font-size: 35px;
        }
      }
    }

    @media (max-width: $mobile) {
      .ad-buttons {
        flex-direction: column;
        width: 100%;
      }
    }

    .video {
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-self: center;
      width: 70%;
      gap: 16px;

      video {
        border-radius: 24px;
        position: relative;
        overflow: hidden;
      }

      @media (max-width: $mobile) {
        width: 90%;
      }
    }

    .video-multiple {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      gap: 16px;

      @media (max-width: 1120px) {
        width: 90%;
        align-items: center;
        flex-direction: column;
        justify-self: center;
      }

      @media (max-width: 700px) {
        align-items: normal;
      }

      video {
        border-radius: 24px;
        max-width: 648px;
        position: relative;
        overflow: hidden;
      }
    }

    .video-container {
      position: relative;
      display: inline-block;

      video {
        height: 100%;
        width: 100%;
      }
    }

    .play-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      color: #0039c7;
      font-size: 30px;
      width: 101px;
      height: 101px;
      border-radius: 100%;
      cursor: pointer;
      z-index: 2;
      border: 10px solid #c0c6fc80;
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    .play-button.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .play-button:hover {
      background-color: rgb(238, 237, 237);
    }

    .help-block {
      display: flex;
      justify-content: center;
      margin: 80px 0;
      align-items: center;
      border: solid 2px #0039c7;
      width: 90%;
      height: 410px;
      border-radius: 64px;
      background-size: 300%;
      background-repeat: no-repeat;
      overflow: hidden;
      background-image: url("/assets/images/screens-bg-form.png");
      background-position: center;

      .content {
        border-radius: 40px;
        width: 100%;
        height: 350px;
        margin: 0 32px;
        background: #e5edff;

        h1 {
          font-weight: 800;
          font-size: 40px;
          padding-top: 40px;
        }

        h2 {
          font-weight: 800;
          font-size: 35px;
          padding-top: 40px;
        }

        span {
          font-weight: 800;
          font-size: 16px;
          color: #6c7393;
        }

        .credential-block {
          .credentials {
            margin-top: 40px;
            display: flex;
            font-weight: 800;
            justify-content: center;
            align-items: center;
            background: #d6e2ff;
            border-radius: 20px;
            color: #0039c7;
            font-size: 18px;
            padding: 20px;
            gap: 10px;
          }
        }
      }

      @media (max-width: $mobile) {
        background: none;
        border: none;
        .content {
          width: 100%;
          height: 100%;
          margin: 0;
          h1 {
            font-size: 35px;
          }
          h2 {
            font-size: 26px;
          }
          .credential-block {
            padding-top: 10px;
            flex-direction: column !important;
            width: 90%;
            justify-self: center;
            .credentials {
              margin: 0;
              flex-direction: row;
              padding: 16px;
            }
          }
        }
      }
    }

    .welcome-text {
      display: flex;
      max-width: 70%;
      font-size: 20px;
      padding-top: 28px;
    }

    .second-bg-image {
      position: absolute;
      right: 0;
      top: 89px;
      width: 1240px;
      height: 628px;
      z-index: 0;
      @media (max-width: $mobile) {
        display: none;
      }
    }

    .not-found-second-bg-image {
      position: absolute;
      right: 0;
      top: 89px;
      width: 1240px;
      height: 628px;
      z-index: 0;
      @media (max-width: $mobile) {
        top: 260px !important;
        right: -500px;
      }
    }

    .blue-container {
      position: relative;
      padding-top: 70px;
      height: 1030px;

      @media (max-width: $mobile) {
        height: 1330px;
      }

      .upper-elipse {
        display: flex;
        position: absolute;
        align-self: center;
        justify-self: center;
        top: 90px;
        height: 392px;
        width: 150%;
        right: -23%;
        background-color: #0039c7;
        border-radius: 50%;
        z-index: 0;
      }

      .content {
        display: flex;
        justify-content: center;
        gap: 126px;
        align-items: center;
        height: 100%;

        .start {
          padding-left: 64px;
          display: flex;
          flex-direction: column;

          .title {
            color: white;
            font-size: 62px;
            font-weight: 800;
            text-align: left;
            padding-bottom: 12px;
            margin: 0;
            padding: 0;
          }

          .card {
            background: #ffffff26;
            max-width: 759px;
            width: 100%;
            color: white;
            padding: 32px 40px;
            border-radius: 40px;

            h2 {
              margin: 0;
              text-align: start;
            }

            p {
              text-align: left;
              padding-top: 16px;
            }

            .number {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 15px;
              min-width: 48px;
              height: 48px;
              background: white;
              color: #0039c7;
              font-size: 24px;
              font-weight: 800;
            }
          }

          span {
            color: white;
            font-size: 16px;
            text-align: left;
          }

          @media (max-width: $mobile) {
            padding-left: 0;
            width: 90%;

            .card {
              width: 100%;

              h2 {
                font-size: 20px;
              }
            }
          }
        }

        .phone {
          width: 470px;
          height: 782px;

          @media (max-width: $mobile) {
            display: none;
          }
        }
      }

      .blue-box {
        position: absolute;
        width: 100%;
        height: 500px;
        top: 200px;
        background: #0039c7;
        z-index: 0;

        @media (max-width: $mobile) {
          height: 1000px;
        }
      }

      .lower-elipse {
        display: flex;
        position: absolute;
        align-self: center;
        justify-self: center;
        bottom: 20px;
        width: 150%;
        right: -23%;
        background-color: #0039c7;
        height: 392px;
        border-radius: 50%;
        z-index: 0;

        @media (max-width: $mobile) {
          bottom: 0;
        }
      }
    }

    .footer {
      margin-top: 10px;
      background: #0039c7;
      width: 100%;
      height: 193px;
      border-radius: 80px 80px 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .upper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        padding: 56px 0;

        select {
          cursor: pointer;
          text-align: center;
          border-radius: 100px;
          min-height: 40px;
          min-width: 110px;
          background: #0039c7;
          border: none;
          color: white;
          box-shadow: 7px 7px 16px 0px #062f93b2;
        }

        .social-media {
          display: flex;
          gap: 15px;
          color: white;
        }

        .social-media .icon {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          background: #0039c7;
          box-shadow: 7px 7px 16px 0px #062f93b2;
          cursor: pointer;
        }

        .social-media .icon svg {
          fill: white; /* Ensures the icon color is white */
        }

        img {
          width: 235px;
          height: 35px;
        }
      }

      .lower {
        display: flex;
        justify-content: space-between;
        width: 80%;
        padding-top: 12px;
        color: #85a8ff;
        font-size: 12px;
        font-weight: 600;
        border-top: solid 1px #85a8ff;
      }

      @media (max-width: $mobile) {
        height: 65%;
        .upper {
          flex-direction: column;
        }

        select {
          order: 2;
          margin-top: 60px;
          margin-bottom: 32px;
          width: 80%;
        }

        .social-media {
          order: 3;
        }

        .lower {
          flex-direction: column;
        }
      }
    }
  }
}
